<template>
  <en-table-layout :tableData="tableData.data" :loading="loading">
    <template slot="toolbar">
      <el-form-item label="操作时间">
        <el-date-picker style="width: 200px" v-model="order_time_range" type="daterange" :editable="false" unlink-panels
          size="small" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="handleDateChange"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="50px" label="进度">
        <el-select style="width: 100px" v-model="params.status" size="small" placeholder="请选择" clearable>
          <el-option label="全部" value="" />
          <el-option label="进行中" value="0" />
          <el-option label="已完成" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :disabled="loading" @click="getList">搜索</el-button>
      </el-form-item>
    </template>
    <template slot="table-columns">
      <el-table-column label="操作类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.type === 0 ? '批量改价' : '单品改价' }}
        </template>
      </el-table-column>
      <el-table-column label="操作商品数" prop="goods_num" align="center" />
      <el-table-column label="成功数" prop="completed_num" align="center" />
      <el-table-column label="操作内容" prop="info" align="center" />
      <el-table-column label="操作人" prop="opt_name" align="center" />
      <el-table-column label="操作时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.opt_time | unixToDate }}
        </template>
      </el-table-column>
      <el-table-column label="进度" align="center">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? '进行中' : '已完成' }}
        </template>
      </el-table-column>
    </template>
    <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
      :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="tableData.data_total"></el-pagination>
  </en-table-layout>
</template>

<script>
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import { getGoodsPriceChange } from '@/api/goods'
export default {
  name: "goodsPriceChange",
  components: {
    EnTableLayout
  },
  data () {
    return {
      tableData: {},
      loading: false,
      params: {
        page_no: 1,
        page_size: 20,
      },
      order_time_range: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    handleDateChange (val) {
      if (val) {
        this.params.opt_time_start = parseInt(val[0].getTime() / 1000);
        this.params.opt_time_end = parseInt(val[1].getTime() / 1000) + 86399;
      } else {
        this.params.opt_time_start = "";
        this.params.opt_time_end = "";
      }
    },
    handlePageSizeChange (val) {
      this.params.page_size = val
      this.getList()
    },
    handlePageCurrentChange (val) {
      this.params.page_no = val
      this.getList()
    },
    getList () {
      getGoodsPriceChange(this.params).then(res => {
        this.tableData = res
      })
    },
  }
}
</script>

<style scoped></style>
